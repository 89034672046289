import React, { useEffect, useState, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  themeConfigState,
  seoDataState,
  currentUserState,
} from '../../common/States';
import { graphql } from 'gatsby';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import LinearProgress from '@material-ui/core/LinearProgress';

import { Alert } from '@material-ui/lab';

import { Auth, Hub, DataStore, Cache } from 'aws-amplify';
//import { UserProfile, Enquiry } from '../../models';

import { navigate } from 'gatsby';

const useStyles = makeStyles(theme => {
  return {
    logo: {
      height: 40,
      [theme.breakpoints.down('sm')]: {
        height: 30,
      },
    },
  };
});

export const query = graphql`
  query getSignedin {
    prismic {
      allThemes {
        edges {
          node {
            dark_background
            dark_text
            error_color
            info_color
            light_background
            warning_color
            light_text
            primary_background
            primary_color
            primary_text
            secondary_background
            secondary_color
            secondary_text
            success_color
            link_primary_color
            link_hover_color
            nav_link_color
            nav_link_hover_color
            nav_link_active_color
          }
        }
      }
      allTop_menu_navigations {
        edges {
          node {
            logo_image
            color
            nav_links_color
            nav_links_hover_color
            nav_links_active_color
            position
            menu_buttons {
              variant
              link {
                ... on PRISMIC_Home_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signup_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signin_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Page {
                  _meta {
                    uid
                  }
                }
              }
              text
              color
              size
            }
            navigation_links {
              link {
                ... on PRISMIC_Home_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signup_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signin_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Page {
                  _meta {
                    uid
                  }
                }
              }
              text
              underline
              variant
            }
          }
        }
      }
      allFooters {
        edges {
          node {
            background_color
            copy_right_text
            facebook_link {
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            instagram_link {
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            site_map {
              label
              link_group
              link {
                ... on PRISMIC_Page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Home_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signup_page {
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Signin_page {
                  _meta {
                    uid
                  }
                }
              }
            }
            text_color
          }
        }
      }
    }
  }
`;

const SignedInPage = props => {
  const setThemeConfig = useSetRecoilState(themeConfigState);
  const setSeoData = useSetRecoilState(seoDataState);
  const setCurrentUser = useSetRecoilState(currentUserState);

  const [signInStatus, setSignInStatus] = useState({
    alertType: 'info',
    alertMessage: 'Signing in',
  });

  const themeFirstEdge = props.data.prismic.allThemes.edges.slice(0, 1).pop();
  const themeConfig = themeFirstEdge.node;

  const checkSignin = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      setCurrentUser({ username: user.username, attributes: user.attributes });
      // const userProfiles = await DataStore.query(UserProfile, up =>
      //   up.email('eq', user.attributes.email)
      // );
      // let userProfile = userProfiles.length === 0 ? null : userProfiles[0];
      // if (!userProfile) {
      //   userProfile = await DataStore.save(
      //     new UserProfile({
      //       email: user.attributes.email,
      //     })
      //   );
      // }
      const userProfile = {
        email: user.attributes.email,
      };
      setSignInStatus({
        alertType: 'success',
        alertMessage: 'Sign in successful',
      });
      if (
        user.attributes.email === 'damian@tripbuddi.com' ||
        user.attributes.email === 'damiansutton@gmail.com' ||
        user.attributes.email === 'damian@simplescalablesolutions.com' ||
        user.attributes.email === 'jb.consulting.is@gmail.com' ||
        user.attributes.email === 'joana@tripbuddi.com' ||
        user.attributes.email === 'joana@simplescalablesolutions.com'
      ) {
        if (user.attributes['custom:accountType'] === 'Agent') {
          navigate('/agent');
        } else {
          const cachedEnquiry = Cache.getItem('newEnquiry');
          if (!!cachedEnquiry) {
            try {
              cachedEnquiry.owner = user.attributes.email;
              // const enquiry = await DataStore.save(new Enquiry(cachedEnquiry));
              // Cache.removeItem('newEnquiry');
              // navigate(`/traveller/enquiries/${enquiry.id}`);
              Cache.removeItem('newEnquiry');
              navigate('/traveller');
            } catch (error) {
              console.error({ error });
            }
          } else {
            navigate('/traveller');
          }
        }
      }
    } catch (error) {
      console.error({ error });
    }
  }, []);

  useEffect(() => {
    checkSignin();
    Hub.listen('auth', async data => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        checkSignin();
      }
    });
  }, []);

  const topMenuFirstEdge = props.data.prismic.allTop_menu_navigations.edges
    .slice(0, 1)
    .pop();
  const topMenuData = topMenuFirstEdge.node;

  const logoImage = topMenuData.logo_image;

  const classes = useStyles();

  useEffect(() => {
    setThemeConfig(themeConfig);
    setSeoData({
      title: 'Tripbuddi Signin Successful',
      description: 'Tripbuddi Signin Successful',
    });
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        zIndex={1}
      >
        <Container maxWidth="xs">
          {signInStatus.alertType === 'info' && <LinearProgress />}
          <Paper className={classes.paper}>
            <Box py={2} textAlign="center" className={classes.title}>
              <AniLink fade duration={1} to="/">
                <img
                  className={classes.logo}
                  src={logoImage.url}
                  alt={logoImage.alt}
                />
              </AniLink>
            </Box>
            <Box px={2} pb={3}>
              <Alert severity={signInStatus.alertType}>
                {signInStatus.alertMessage}
              </Alert>
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default SignedInPage;
